.suc_msg_wrapper {
  text-align: center;
  /* padding: 40px 0; */
  background: #EBF0F5;
  height: 100vh;
}
  h1 {
    color: #88B04B;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 10px;
  }
  p {
    color: #404F5E;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size:20px;
    margin: 0;
  }
i {
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left:-15px;
}
.card {
  /* width: 500px; */
  width: 95vw;
  height: 95vh;
  background: white;
  padding: 60px;
  padding-top: 7rem !important;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 1% auto;
}

@media screen and (max-width: 650px) {

.secondary_text{
  font-size: 14px;
}
h1 {
  font-weight: 700;
}
.card {
  /* width: 500px; */
  width: 100vw;
  height: 100vh;
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 0 auto;
}
}
@media screen and (max-width: 413px) {

  .secondary_text{
    font-size: 12px;
  }
  h1 {
    font-size: 28px;
    font-weight: 500;
  }
  }
.img_wrapper{
  /* max-width: 45vw; */
  max-height: 100vh;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

