.upper_sec {
  height: 350px;
  flex-shrink: 0;
  border-radius: 0px 0px 50px 0px;
  background: linear-gradient(0deg, #0B2931 0%, #0B2931 100%), #046575;
}

.card_wrapper {
  width: 100%;
  margin: 10px auto;
  /* max-width: 380px; */
}

.header_title {
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pay_as_you_go_form,.transaction_id_search_form {
  border-radius: 35px;
  background: #FFF;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
  display: inline-flex;
  padding: 35px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  margin-top: 20px;
}
.transaction_id_search_form{
  width: 100%;
}

.pay_as_you_go_text_wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Add_amount_text {
  color: #1C2348;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.secondary_text {
  color: #8C8C8C;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.gradient_button {
  border-radius: 10px;
  background: var(--gradient, linear-gradient(129deg, #A8C41E 21.35%, #027D8F 70.31%));
  display: flex;
  padding: 10px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}

.amount_input {
  border-radius: 10px;
  border: 0.5px solid rgba(0, 0, 0, 0.30);
  padding: 10px 20px;
  align-items: center;
  width: 100%;
}

.secondary_title {
  color: #AAA;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bundles_title_wrapper {
  color: #0B2931;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.header_wrapper {
  display: flex;
  justify-content: space-between;
  padding: 25px 6.5%;
  color: white;
  background-color: #0b2931;
}

.bundles_title {
  margin: 200px auto 10px;
}

.gradient_text {
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(--gradient, linear-gradient(129deg, #A8C41E 21.35%, #027D8F 70.31%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cards_wrapper {
  margin: "auto";
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  /* Allow cards to wrap to the next line if needed */
  justify-content: space-between;
  /* top:66%;
  right: 50%;
  transform: translate(50%, 40%); */
  flex-wrap: wrap;
  margin: 20px auto !important;
}


.card_wrapper {
  display: flex;
  padding: 35px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  border-radius: 35px;
  background: #FFF;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);

}

.card_text {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.check_mark {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.choose_bundle_button {
  border-radius: 10px;
  background: var(--gradient, linear-gradient(129deg, #A8C41E 21.35%, #027D8F 70.31%));
  display: flex;
  width: 100%;
  padding: 10px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.images_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.image_text_container {
  display: flex;
  align-items: center;
  /* width: 50px; */
  height: 60px;
}

.text_container {
  display: flex;
  flex-direction: column;
  margin: 6px 11px 6px 11px;
}

.text_container span {
  margin-bottom: 5px;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.price {
  color: #1C2348;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}





.type_header {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.type_text {
  color: #8c8c8c;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin-top: 5px;
}

.check {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 8px;
  margin-top: 8px;
}


.card_item_wrapper {
  display: flex;
  width: 100%;
  font-size: 12px;
  flex-wrap: wrap;
}

.header_icon {
  width: 60px;
}

.images_wrapper {
  display: flex;
  align-items: center;
}

.user_img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.pay_as_you_go_wrapper,.transaction_id_search{
  display: flex;
  width: 92%;
  margin: auto;
  flex-direction: column;
  align-items: center;
}

/* 
@media screen and (max-width: 1024px) {
 
} */
.acc_item{
  width:50%;
}
@media screen and (min-width: 660px) {

  .card_wrapper {
    width: 80%;
  }

  .header_title {
    font-size: 25px;
    padding-right: 20px;
    text-align: start;
  }

  .pay_as_you_go_text_wrapper {
    align-items: flex-start;
    text-align: start;
    max-width: 45%;

  }

  .pay_as_you_go_form ,.transaction_id_search_form{
    margin-top: 16px;
    max-width: 45%;
  }

  .pay_as_you_go_wrapper {
    flex-direction: row;
    width: 87%;
    justify-content: space-between;
    align-items: baseline;
  }

  .bundles_title {
    margin: 10px auto 10px;
  }

  .upper_sec {
    height: fit-content;
    padding-bottom: 25px;
  }

  /* .bundles_title{
    margin-top: 240px;
  } */
}

@media screen and (min-width: 760px) {
  .header_title {
    font-size: 30px;
  }
}

@media screen and (min-width: 860px) {
  .card_wrapper {
    width: 45%;
  }
}

@media screen and (min-width: 1210px) {
  .card_wrapper {
    width: 32%;
  }
 
}

@media screen and (max-width: 670px) {
  /* .acc_item{
    width: 45%;
  } */
  .text_container span {
    font-size: 10px;
  }

  .card_wrapper {
    margin: 10px auto;
  }

  /* .card_wrapper {
    width: 100%;
    margin: 10px auto;
  } */

  .header_icon {
    width: 30px;
  }

  .header_secondary_icon {
    width: 50px;
  }

  /* .header_wrapper {
    padding: 10px 30px;
  } */

}

.toggle_button{
width: 150px;
text-align: center;
}


@media screen and (max-width: 390px) {

  .header_title {
    font-size: 16px;
  }

  .secondary_text {
    font-size: 14px;
  }

}

.toggle_buttons_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
  background: white;
 
}

.toggle_button {
  padding: 4px;
  font-weight: 500;
}

.selected_button {
  color: white ;
  background: var(--gradient, linear-gradient(129deg, #A8C41E 21.35%, #027D8F 70.31%));
  border-radius: 8px !important;
}